import React, {useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row
} from 'react-bootstrap';

import { chorPW } from "../../stuff/chorPw";
import finanzen from "../../imgs/chor/Finanzen.pdf";





const Chor = () => {

  // =====================================================================================================================
  // Password Checking for displaying the whole subsite content 
  const [hasAccess, setHasAccess] = useState(false);

  function checkPassword() {
    const typedPassword = document.getElementById("password").value;

    if (typedPassword === chorPW) {
      setHasAccess(true);
    } else {
      alert("Doesn't seem like it");
    }
  };
  // =====================================================================================================================

  if (!hasAccess) {
    return (
      <Card className="m-4">
        <Card.Header>
          Do you have access here? :)
        </Card.Header>
        <Card.Body className="p-4">
          <Row className="d-flex align-items-center">
            <Form as={Col} xs={9} sm={8} md={6} lg={4}>
              <Form.Group className="m-0 p-0 mb-2 mt-2" controlId="password">
                <Row>
                  <Col className='mr-0 ml-0 pr-0 pl-0'>
                    <Form.Label>
                      Password
                    </Form.Label>
                  </Col>
                  
                  <Col className='mr-0 ml-0 pr-0 pl-0'>
                    <Form.Control type="password" placeholder="Type Password here" />
                  </Col>
                </Row>
              </Form.Group>
            </Form>

            <Col xs={3} sm={4} md={3} lg={2}>
              <Button
                variant="secondary"
                onClick={ () => {
                  checkPassword();
                }}
              >
                Enter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  } else return (
    <Card className="m-4">
      <Card.Header>
        kleiner Hub für all unsere Chor Sachen :)
      </Card.Header>

      <Card.Body className='p-4'>

        <h3>Finances</h3>

        <iframe src={ finanzen } width="80%" height="800" title="finances.pdf"/>
        
      </Card.Body>
    </Card>
  )
};

export default Chor;